<template>
  <div class="row mx-0 white_bg">
    <div class="col-12 py-5">
      <div class="container">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-12 fs-2 mb-5">
              Sign up
            </div>
            <div class="col-12 mb-3">
              Simplest option selected: <b class="blue_font">{{currentPackage.name}}</b>
            </div>
            <div class="col-12 mb-3">
              <CheckDomain @setDomainName="setDomain"/>
            </div>
            <!--USER DETAILS-->
            <div class="col-12">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label">First name<sup>*</sup></label>
                  <input type="text" class="form-control" placeholder="First name" aria-label="First name" v-model="user.firstName" required :class="{error: !user.firstName}">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Last name</label>
                  <input type="text" class="form-control" placeholder="Last name" aria-label="First name" v-model="user.lastName">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Phone number</label>
                  <input type="tel" class="form-control" placeholder="Phone number" aria-label="First name" v-model="user.phone">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Email address<sup>*</sup></label>
                  <input type="email" class="form-control" placeholder="Email address" aria-label="Email address" v-model="user.email" required :class="{error: !user.email}">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Address</label>
                  <input type="text" class="form-control" placeholder="Address" aria-label="Address" v-model="user.address">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">City</label>
                  <input type="text" class="form-control" placeholder="City" aria-label="Address" v-model="user.city">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Province</label>
                  <input type="text" class="form-control" placeholder="Province" aria-label="Address" v-model="user.province">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Country</label>
                  <input type="text" class="form-control" placeholder="Country" aria-label="Address" v-model="user.country">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Postal Code</label>
                  <input type="number" class="form-control" placeholder="Postal Code" aria-label="Address" v-model="user.postalCode">
                </div>
              </div>
            </div>
            <!--END USER DETAILS-->
            <div class="col-12 mb-3" v-if="packageType === 1 || packageType === 2">
              We will be in contact to get the content for the site.
              <!-- <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label">Page 1 Title</label>
                  <input type="text" class="form-control" placeholder="Page 1 Title" aria-label="Page 1 Title" v-model="siteDetails.page1.title">
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label">Last name</label>
                  <input type="text" class="form-control" placeholder="Last name" aria-label="First name" v-model="lastName">
                </div>
              </div> -->
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-blue px-5" :disabled="!isReady || isSending">
                Submit
                <div class="spinner-border spinner-border-sm" role="status" v-if="isSending">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
            <div class="col-12" v-if="errorSending">
              There was an error sending your request, please try again later.
            </div>
          </div>
        </form>
        <!-- {{currentPackage}} -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CheckDomain from '../components/CheckDomain.vue'
// @ is an alias to /src

export default {
  components: { CheckDomain },
  name: 'SignUp',
  data () {
    return {
      packages: require('@/static/packages.json'),
      domainName: null,
      user: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        address: null,
        city: null,
        province: null,
        country: null,
        postalCode: null
      },
      isSending: false,
      errorSending: false
    }
  },
  computed: {
    ...mapGetters([
      'homeNavStatus',
      'isMobile'
    ]),
    packageType () {
      return parseInt(this.$route.params.id)
    },
    currentPackage () {
      return this.packages.find(item => {
        if (item.key !== this.packageType) {
          return false
        }
        return true
      })
    },
    isReady () {
      if (this.domainName && this.user.firstName && this.user.email) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions([
      'sendFormDetails'
    ]),
    setDomain (val) {
      console.log('setDomain', val)
      this.domainName = val
    },
    async onSubmit () {
      this.isSending = true
      console.log('submit details', this.user, this.currentPackage)
      const details = {
        user: this.user,
        option: this.currentPackage,
        domain: this.domainName
      }
      const retSend = await this.sendFormDetails(details)
      if (retSend.message === 'Queued. Thank you.') {
        window.scrollTo(0, 0)
        this.$router.push('/success')
      } else {
        this.errorSending = true
      }
      this.isSending = false
    }
  }
}
</script>
<style scoped>

</style>
