<template>
  <div class="row">
    <div class="col-md-9">
      <label for="inputDomain" class="form-label">Domain name</label>
      <div class="input-group" >
        <input type="text" class="form-control" placeholder="Domain name" aria-label="Domain name" aria-describedby="button-addon2" v-model="domainName" :class="{error: status && !isAvailable}">
        <span class="input-group-text">.co.za</span>
        <button class="btn" type="button" id="button-addon2" disabled v-if="status" :class="{'btn-green': status === 'Available', 'btn-red': status === 'Unavailable', 'btn-blue': status === 'Checking'}">
          {{status}}
          <div class="spinner-border spinner-border-sm" role="status" v-if="status === 'Checking'">
            <span class="visually-hidden">Loading...</span>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16" v-else-if="status === 'Available'">
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" v-else-if="status === 'Unavailable'">
            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
          </svg>
        </button>
        <button class="btn btn-blue" type="button" id="button-addon2" @click="checkAvailable" :disabled="!ready" v-else>Check<span class="d-none d-md-inline-block">&nbsp;if available</span></button>
      </div>
      <div id="domainHelp" class="form-text" v-if="!ready">
        Your domain must be more than 3 characters long.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CheckDomain',
  data () {
    return {
      domainName: null,
      ready: false,
      isAvailable: false,
      status: null
    }
  },
  mounted () {
    this.status = null
    this.domainName = null
    this.ready = false
  },
  watch: {
    domainName () {
      this.status = null
      if (this.domainName.length > 3) {
        console.log(this.domainName.length)
        this.ready = true
      } else {
        this.ready = false
      }
    },
    status () {
      console.log('STATUS', this.domainName)
      if (this.status === 'Available') {
        this.$emit('setDomainName', this.domainName)
      } else {
        this.$emit('setDomainName', null)
      }
    }
  },
  methods: {
    ...mapActions([
      'checkDomainStatus'
    ]),
    async checkAvailable () {
      this.status = 'Checking'
      const ret = await this.checkDomainStatus(this.domainName)
      if (ret.data === 'taken') {
        this.isAvailable = false
        this.status = 'Unavailable'
      } else if (ret.data === 'available') {
        this.isAvailable = true
        this.status = 'Available'
      } else {
        this.isAvailable = false
        this.status = 'Unavailable'
      }
    }
  }
}
</script>
